import { EmployerBannerNotificationsService, EmployerMarketingCpaService } from '@alliance/employer/api'
import { EmployerRightsService } from '@alliance/employer/gql-domain'
import { AuthWithOtpService } from '@alliance/shared/auth-with-otp/domain'
import { AuthService } from '@alliance/shared/auth/api'
import { FooterData } from '@alliance/shared/footer'
import { HeaderData, HeaderHelperService, HeaderModeEnum, HeaderPositionModeEnum } from '@alliance/shared/header/utils'
import { MobileBarData } from '@alliance/shared/mobile-navigation-bar/utils'
import { RxStateComponent } from '@alliance/shared/models'
import { cancelRedirectAfterLogin, DetectPlatformService, HelpersService, NgZoneHelperService, UserSideEnum } from '@alliance/shared/utils'
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { ActivationEnd, Event, Router } from '@angular/router'
import { LOCATION, WINDOW } from '@ng-web-apis/common'
import { fromEvent, combineLatest } from 'rxjs'
import { filter, first, map, switchMap } from 'rxjs/operators'
import { SignalrMessageService, StreamTypeMap } from '@alliance/socket/api'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.tw.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent extends RxStateComponent<{
  userSide: UserSideEnum | null
  headerData: HeaderData | null
  footerData: FooterData | null
  mobileBarData: MobileBarData | null
  socialLogin: boolean
  refreshLogin: string | null
  hideUaBanner: boolean
  isEmployer: boolean
  isSeeker: boolean
}> {
  public readonly state = this.connectViewState(['userSide', 'headerData', 'footerData', 'mobileBarData', 'hideUaBanner', 'isEmployer', 'isSeeker'])

  private readonly DEFAULT_HEADER_DATA: HeaderData = {
    headerMode: HeaderModeEnum.innerPage,
    positionMode: HeaderPositionModeEnum.sticky,
    isShowHeader: true,
    preventRenderHeaderIfMobile: false
  }

  private readonly DEFAULT_FOOTER_DATA: FooterData = {
    isShowFooter: true,
    preventRenderFooterIfMobile: false
  }

  public constructor(
    @Inject(LOCATION) private readonly location: Location,
    @Inject(WINDOW) private readonly windowRef: Window,
    private platform: DetectPlatformService,
    private authService: AuthService,
    private employerRightsService: EmployerRightsService,
    private employerBannerNotificationsService: EmployerBannerNotificationsService,
    private router: Router,
    private headerHelperService: HeaderHelperService,
    private ngZoneHelper: NgZoneHelperService,
    private helpersService: HelpersService,
    private authWithOtpService: AuthWithOtpService,
    private employerMarketingCpaService: EmployerMarketingCpaService,
    private signalrMessageService: SignalrMessageService
  ) {
    super()
    this.initState({
      userSide: null,
      headerData: null,
      footerData: null,
      mobileBarData: null,
      socialLogin: false,
      refreshLogin: this.select('socialLogin').pipe(
        filter(value => !!value),
        switchMap(() => {
          this.set({ socialLogin: false })
          return this.authService.refresh()
        })
      ),
      hideUaBanner: false,
      isEmployer: this.authService.token$.pipe(map(token => !!token && this.authService.isEmployer)),
      isSeeker: this.authService.token$.pipe(map(token => !!token && this.authService.isSeeker))
    })

    this.handleSocialLoginMessage()
    this.listenSocketsForEmployer()
    this.listenSocketsForSeekers()

    this.employerMarketingCpaService.subscribeToCpaRegister()
  }

  public get isBrowser(): boolean {
    return this.platform.isBrowser
  }

  public routerOnActivate(): void {
    this.headerHelperService.clearBottomContent()

    this.hold(this.router.events.pipe(first((event: Event): boolean => event instanceof ActivationEnd && !!event?.snapshot?.data?.['userSide'])), (event: Event | undefined) => {
      if (event && event instanceof ActivationEnd && !!event.snapshot.data?.['userSide']) {
        this.set({
          userSide: event.snapshot.data['userSide'] as UserSideEnum,
          headerData: { ...this.DEFAULT_HEADER_DATA, ...(event.snapshot.data['headerData'] as HeaderData) },
          footerData: { ...this.DEFAULT_FOOTER_DATA, ...(event.snapshot.data['footerData'] as FooterData), ...(this.helpersService.isApp() && { isShowFooter: false }) },
          mobileBarData: event.snapshot.data['mobileBar'] as MobileBarData,
          hideUaBanner: !!event.snapshot.data['hideUaBanner']
        })
      }
    })
  }

  private handleSocialLoginMessage(): void {
    if (this.platform.isBrowser) {
      this.hold(
        fromEvent(this.windowRef, 'message').pipe(
          filter(event => event instanceof MessageEvent && event.data === 'rua_social_login_second_tab_success'),
          this.ngZoneHelper.outsideNgZone()
        ),
        () => {
          this.getInitialPage()
          this.set({ socialLogin: true })
        }
      )
    }
  }

  private listenSocketsForEmployer(): void {
    if (this.platform.isBrowser) {
      // FOR EMPLOYER - LISTEN TO EMPLOYER RIGHTS' CHANGES VIA SOCKET
      this.employerRightsService.subscribeToEmployerRightsChanges()

      // FOR EMPLOYER - LISTEN TO EMPLOYER BANNER NOTIFICATION VIA SOCKET
      this.employerBannerNotificationsService.subscribeToEmployerBannerNotifications()
    }
  }

  private listenSocketsForSeekers(): void {
    this.hold(
      combineLatest([
        this.authService.token$.pipe(
          map(token => !!token),
          filter(Boolean)
        ),
        this.signalrMessageService.getStreamByType(StreamTypeMap.seekerAddToBlackList)
      ]),
      () => {
        this.hold(this.authService.logout(), () => {
          this.router.navigate(['/auth/login'])
        })
      }
    )
  }

  private getInitialPage(): void {
    if (!this.authService.isEmployer) {
      if (this.authWithOtpService.get('redirectLink')) {
        this.router.navigate([this.authWithOtpService.get('redirectLink')])
        return
      }

      if (cancelRedirectAfterLogin(this.location.pathname)) {
        return
      }
      this.router.navigate(['/my/recommendations'], {
        queryParams: {
          afterLogin: true
        }
      })
    } else {
      this.router.navigate(['/my'])
    }
  }
}
